import { FuseNavigationItem } from '@fuse/components/navigation';

export const commonNavigation: FuseNavigationItem[] = [
  {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'basic',
    link: '/analytics',
  },
  {
    id: 'merchants',
    title: 'Merchants',
    type: 'basic',
    link: '/onboardings',
  },
  {
    id: 'users',
    title: 'Application users',
    type: 'basic',
    link: '/appusers',
  },
  {
    id: 'orders',
    title: 'Orders',
    type: 'basic',
    link: '/orders/list',
  },

  {
    id: 'reports',
    title: 'Reports',
    type: 'collapsable',
    children: [
      {
        id: 'reports',
        title: 'Reports for KNF',
        type: 'basic',
        link: '/reports/type/orders',
      },
      {
        id: 'jpk-reports',
        title: 'JPK',
        type: 'basic',
        link: '/reports/jpk',
      },
    ],
  },
  {
    id: 'documents',
    title: 'Apps documents & templates',
    type: 'collapsable',
    children: [
      {
        id: 'messages',
        title: 'Messages',
        type: 'collapsable',
        children: [
          {
            id: 'sms-templates-merchant',
            title: 'Merchant templates',
            type: 'basic',
            link: '/templates/sms/MERCHANT/list',
          },
          {
            id: 'sms-templates-mobile',
            title: 'Mobile templates',
            type: 'basic',
            link: '/templates/sms/MOBILE/list',
          },
          {
            id: 'sms-templates-user',
            title: 'User templates',
            type: 'basic',
            link: '/templates/sms/USER/list',
          },
        ],
      },
      {
        id: 'appdocuments',
        title: 'Apps documents',
        type: 'collapsable',
        children: [
          {
            id: 'termsAndConditionsManagementMobile',
            title: 'Terms and Conditions',
            type: 'basic',
            link: '/documents/mobile-app/terms-conditions',
          },
          {
            id: 'privacyPolicyMobile',
            title: 'Privacy policy',
            type: 'basic',
            link: '/documents/mobile-app/privacy-policy',
          },
          {
            id: 'quickoTermsAndConditionsManagementMobile',
            title: 'Quicko Terms and Conditions',
            type: 'basic',
            link: '/documents/mobile-app/quicko/terms-conditions',
          },
          {
            id: 'quicko/privacyPolicyMobile',
            title: 'Quicko Privacy policy',
            type: 'basic',
            link: '/documents/mobile-app/quicko/privacy-policy',
          },
        ],
      },
      {
        id: 'consentChildUnder13',
        title: 'Consent child under 13',
        type: 'basic',
        link: '/documents/mobile-app/consent-child-13',
      },
      {
        id: 'consentChild1318',
        title: 'Consent child 13-18',
        type: 'basic',
        link: '/documents/mobile-app/consent-child-13-18',
      },
      {
        id: 'merchantListOrder',
        title: 'Merchant list ordering',
        type: 'basic',
        link: '/merchants/list/order',
      },
      {
        id: 'translations',
        title: 'Translations',
        type: 'basic',
        link: '/translations/pl/list',
      },
    ],
  },
  {
    id: 'transactions',
    title: 'Banks & reporting',
    type: 'collapsable',
    children: [
      {
        id: 'pspTransactions',
        title: 'PSP Transactions',
        type: 'basic',
        link: '/psp-transaction-log',
      },
      {
        id: 'pspSettlements',
        title: 'PSP Settlements',
        type: 'basic',
        link: '/psp/settlements',
      },
      {
        id: 'topups',
        title: 'Fizen topups',
        type: 'basic',
        link: '/topups/list',
      },
      {
        id: 'bankLedger',
        title: 'Bank ledger history',
        type: 'basic',
        link: '/bankLedger/list',
      },
      {
        id: 'banktransfersOrders',
        title: 'Bank transfer orders',
        type: 'basic',
        link: 'bankTransfersOrders/list',
      },
    ],
  },
  {
    id: 'system',
    title: 'System configuration',
    type: 'collapsable',
    children: [
      {
        id: 'users',
        title: 'System users',
        type: 'basic',
        link: '/users',
      },
      {
        id: 'configs',
        title: 'Global config',
        type: 'basic',
        link: '/kyc/configs',
      },
      {
        id: 'bankTopup',
        title: 'Bank Topup',
        type: 'basic',
        link: '/bankTopup',
      },
      {
        id: 'bankAccounts',
        title: 'Bank account config',
        type: 'basic',
        link: '/bankAccounts',
      },
      {
        id: 'sanctionList',
        title: 'Sanction list',
        type: 'basic',
        link: '/sanctionList',
      },
      {
        id: 'banks',
        title: 'Bank list',
        type: 'basic',
        link: '/banks',
      },
    ],
  },
  {
    id: 'beta',
    title: 'Beta',
    type: 'collapsable',
    children: [
      {
        id: 'kycphonewhitelist',
        title: 'Phone whitelist',
        type: 'basic',
        link: '/kyc/phone-whitelist',
      },
      {
        id: 'btwhitelist',
        title: 'BT Whitelist',
        type: 'basic',
        link: '/btwhitelist/list',
      },
      // {
      //   id: 'pspTransactionList',
      //   title: 'PSP Transaction Log',
      //   type: 'basic',
      //   link: '',
      // },
      {
        id: 'mfaWhitelist',
        title: 'Mfa Whitelist',
        type: 'basic',
        link: '/mfaWhitelist',
      },
      // {
      //   id: 'getBasketLogs',
      //   title: 'getBasketLogs',
      //   type: 'basic',
      //   link: '/logs/getBasket',
      // },
      {
        id: 'sqs-error',
        title: 'List of SQS errors',
        type: 'basic',
        link: '/sqs-error/list',
      },
      {
        id: 'parameterStore',
        title: 'Runtime Parameters',
        type: 'basic',
        link: '/parameters',
      },
      {
        id: 'appusers-whitelist',
        title: 'Blacklist',
        type: 'basic',
        link: '/appusers-blacklist/list',
      },
      {
        id: 'kyc-test-cases',
        title: 'Kyc Test Cases',
        type: 'basic',
        link: '/kyc-test-cases/list',
      },
      {
        id: 'kyc-test-cases',
        title: 'Kyc Review',
        type: 'basic',
        link: '/kyc/verification',
      },
      {
        id: 'failures-orders',
        title: 'List of failure orders',
        type: 'basic',
        link: '/orders-failures/list',
      },
    ],
  },
  {
    id: 'logout',
    title: 'Logout',
    type: 'basic',
    link: '/sign-out',
  },
  // {
  //   id: 'merchants-panel',
  //   title: 'Merchants panel',
  //   subtitle: 'Managing the merchants in OpenApp',
  //   type: 'group',
  //   link: '/merchants',
  //   children: [
  //     {
  //       id: 'merchants',
  //       title: 'Merchants',
  //       type: 'basic',
  //       icon: 'heroicons_outline:user-group',
  //       link: '/merchants',
  //     },
  //     {
  //       id: 'documentsForApp',
  //       title: 'Documents',
  //       type: 'collapsable',
  //       icon: 'heroicons_outline:desktop-computer',
  //       children: [
  //         {
  //           id: 'termsAndConditionsManagementApp',
  //           title: 'Terms and Conditions Mobile',
  //           type: 'basic',
  //           link: '/documents/merchant-app/terms-conditions',
  //           icon: 'heroicons_outline:clipboard-list',
  //         },
  //         {
  //           id: 'privacyPolicyApp',
  //           title: 'Privacy policy',
  //           type: 'basic',
  //           icon: 'heroicons_outline:finger-print',
  //           link: '/documents/merchant-app/privacy-policy',
  //         },
  //         {
  //           id: 'contractMobile',
  //           title: 'Contract',
  //           type: 'basic',
  //           icon: 'heroicons_outline:document-text',
  //           link: '/documents/merchant-app/contract',
  //         },
  //       ],
  //     },
  //     {
  //       id: 'onboardings',
  //       title: 'Onboardings',
  //       type: 'basic',
  //       icon: 'heroicons_outline:clipboard-check',
  //       link: '/onboardings',
  //     },
  //     {
  //       id: 'templates',
  //       title: 'Templates',
  //       type: 'collapsable',
  //       icon: 'heroicons_outline:template',
  //       link: '/merchants',
  //       children: [
  //         {
  //           id: 'sms-templates',
  //           title: 'Sms',
  //           type: 'basic',
  //           icon: 'heroicons_outline:chat-alt',
  //           link: '/templates/sms/MERCHANT/list',
  //         },
  //         {
  //           id: 'email-templates',
  //           title: 'Email',
  //           type: 'basic',
  //           icon: 'heroicons_outline:document-text',
  //           link: '/templates/emails/MERCHANT/list',
  //         },
  //       ],
  //     },
  //   ],
  // },

  // //MOBILE APP

  // {
  //   id: 'mobile-app',
  //   title: 'Mobile application',
  //   subtitle: 'Managing the mobile application in OpenApp',
  //   type: 'group',
  //   link: '/merchants',
  //   children: [
  //     {
  //       id: 'analytics',
  //       title: 'Analytics',
  //       type: 'basic',
  //       icon: 'heroicons_outline:chart-bar',
  //       link: '/analytics',
  //     },
  //     {
  //       id: 'users',
  //       title: 'Application users',
  //       type: 'basic',
  //       icon: 'heroicons_outline:users',
  //       link: '/appusers',
  //     },
  //     {
  //       id: 'documents',
  //       title: 'Documents',
  //       type: 'collapsable',
  //       icon: 'heroicons_outline:pencil-alt',
  //       children: [
  //         {
  //           id: 'termsAndConditionsManagementMobile',
  //           title: 'Terms and Conditions',
  //           type: 'basic',
  //           link: '/documents/mobile-app/terms-conditions',
  //           icon: 'heroicons_outline:clipboard-list',
  //         },
  //         {
  //           id: 'privacyPolicyMobile',
  //           title: 'Privacy policy',
  //           type: 'basic',
  //           icon: 'heroicons_outline:finger-print',
  //           link: '/documents/mobile-app/privacy-policy',
  //         },
  //         {
  //           id: 'consentChildUnder13',
  //           title: 'Consent child under 13',
  //           type: 'basic',
  //           icon: 'heroicons_outline:emoji-happy',
  //           link: '/documents/mobile-app/consent-child-13',
  //         },
  //         {
  //           id: 'consentChild1318',
  //           title: 'Consent child 13-18',
  //           type: 'basic',
  //           icon: 'heroicons_outline:user',
  //           link: '/documents/mobile-app/consent-child-13-18',
  //         },
  //       ],
  //     },
  //     {
  //       id: 'templates',
  //       title: 'Templates',
  //       type: 'collapsable',
  //       icon: 'heroicons_outline:template',
  //       link: '/merchants',
  //       children: [
  //         {
  //           id: 'sms-templates',
  //           title: 'Sms',
  //           type: 'basic',
  //           icon: 'heroicons_outline:chat-alt',
  //           link: '/templates/sms/MOBILE/list',
  //         },
  //         {
  //           id: 'email-templates',
  //           title: 'Email',
  //           type: 'basic',
  //           icon: 'heroicons_outline:document-text',
  //           link: '/templates/emails/MOBILE/list',
  //         },
  //       ],
  //     },
  //     {
  //       id: 'kyc',
  //       title: 'Know your customer',
  //       type: 'collapsable',
  //       icon: 'heroicons_outline:eye',
  //       children: [
  //         {
  //           id: 'configs',
  //           title: 'Global config',
  //           type: 'basic',
  //           icon: 'heroicons_outline:cog',
  //           link: '/kyc/configs',
  //         },
  //         {
  //           id: 'kycphonewhitelist',
  //           title: 'Phone whitelist',
  //           type: 'basic',
  //           icon: 'heroicons_outline:clipboard-check',
  //           link: '/kyc/phone-whitelist',
  //         },
  //       ],
  //     },
  //     {
  //       id: 'btwhitelist',
  //       title: 'BT Whitelist',
  //       type: 'basic',
  //       icon: 'heroicons_outline:clipboard-list',
  //       link: '/btwhitelist/list',
  //     },
  //   ],
  // },

  // // BANKS

  // {
  //   id: 'banks',
  //   title: 'Banks',
  //   type: 'group',
  //   icon: 'heroicons_outline:library',
  //   link: '/merchants',
  //   subtitle: 'List of integrated banks',
  //   children: [
  //     {
  //       id: 'bankAccounts',
  //       title: 'Bank account config',
  //       type: 'basic',
  //       icon: 'heroicons_outline:library',
  //       link: '/bankAccounts',
  //     },
  //     {
  //       id: 'bankTransfer',
  //       title: 'Bank Transfer',
  //       type: 'basic',
  //       icon: 'heroicons_outline:inbox-in',
  //       link: '/bankTransfers',
  //     },
  //   ],
  // },

  // //ADMINISTRATION

  // {
  //   id: 'developer',
  //   title: 'Administration',
  //   type: 'group',
  //   icon: 'heroicons_outline:shopping-bag',
  //   link: '/fakeBaskets',
  //   children: [
  //     {
  //       id: 'mfaWhitelist',
  //       title: 'Mfa Whitelist',
  //       type: 'basic',
  //       icon: 'heroicons_outline:clipboard-list',
  //       link: '/mfaWhitelist',
  //     },
  //     {
  //       id: 'parameterStore',
  //       title: 'Runtime Parameters',
  //       type: 'basic',
  //       icon: 'heroicons_outline:cog',
  //       link: '/parameters',
  //     },
  //     {
  //       id: 'users',
  //       title: 'System users',
  //       type: 'basic',
  //       icon: 'heroicons_outline:users',
  //       link: '/users',
  //     },
  //     {
  //       id: 'sanctionList',
  //       title: 'Sanction list',
  //       type: 'basic',
  //       icon: 'heroicons_outline:collection',
  //       link: '/sanctionList',
  //     },
  //     {
  //       id: 'templates',
  //       title: 'Templates',
  //       type: 'collapsable',
  //       icon: 'heroicons_outline:template',
  //       link: '/merchants',
  //       children: [
  //         {
  //           id: 'sms-templates',
  //           title: 'Sms',
  //           type: 'basic',
  //           icon: 'heroicons_outline:chat-alt',
  //           link: '/templates/sms/ADMIN/list',
  //         },
  //         {
  //           id: 'email-templates',
  //           title: 'Email',
  //           type: 'basic',
  //           icon: 'heroicons_outline:document-text',
  //           link: '/templates/emails/ADMIN/list',
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export const defaultNavigation: FuseNavigationItem[] = [...commonNavigation];
export const compactNavigation: FuseNavigationItem[] = [...commonNavigation];
export const futuristicNavigation: FuseNavigationItem[] = [...commonNavigation];
export const horizontalNavigation: FuseNavigationItem[] = [...commonNavigation];
