/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { Partial_any_ } from '../models/Partial_any_';
import type { Record_string_any_ } from '../models/Record_string_any_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class BankBranchesService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @returns void
     * @throws ApiError
     */
    public reload(): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/bankBranches/reload',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public createIndex(): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/bankBranches/index',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public deleteIndex(): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'DELETE',
            url: '/bankBranches/index',
        });
    }

    /**
     * @returns void
     * @throws ApiError
     */
    public loadTestData(): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/bankBranches/loadTestData',
        });
    }

    /**
     * @param query
     * @returns Record_string_any_ Ok
     * @throws ApiError
     */
    public searchBySortCode(
        query: string = '',
    ): Observable<Record_string_any_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/bankBranches/searchBySortCode',
            query: {
                'query': query,
            },
        });
    }

    /**
     * @param query
     * @returns Record_string_any_ Ok
     * @throws ApiError
     */
    public searchByBankCode(
        query: string = '',
    ): Observable<Record_string_any_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/bankBranches/searchByBankCode',
            query: {
                'query': query,
            },
        });
    }

    /**
     * @param query
     * @returns Partial_any_ Ok
     * @throws ApiError
     */
    public searchByIban(
        query: string = '',
    ): Observable<Partial_any_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/bankBranches/searchByIban',
            query: {
                'query': query,
            },
        });
    }

}