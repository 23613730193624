import { Store } from '@datorama/akita';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export abstract class StateManagementService<T> {
  constructor(protected store: Store<T>) {}

  protected parseError(error: any) {
    return error?.body?.publicTranslationKey || error || 'exception.unknownException';
  }

  protected async execute<K>(action: Observable<K>, onSuccess?: (data: K) => Promise<void>, onError?: (e) => void | Promise<void>) {
    this.store.setLoading(true);
    this.store.setError(null);

    let data: K;

    try {
      data = await action.pipe(take(1)).toPromise();
      await onSuccess(data);
    } catch (e) {
      if (onError) {
        this.store.setLoading(false);

        await onError(e);
      } else {
        this.store.setError(this.parseError(e));
      }
    }

    this.store.setLoading(false);

    return data;
  }
}
