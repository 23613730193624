/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { GenerateITPBody } from '../models/GenerateITPBody';
import type { JPKDeclaration } from '../models/JPKDeclaration';
import type { JPKDeclarationStatus } from '../models/JPKDeclarationStatus';
import type { Omit_GenerateMerchantITPZPayload_merchantId_or_adminUserId_ } from '../models/Omit_GenerateMerchantITPZPayload_merchantId_or_adminUserId_';
import type { Paginated_JPKDeclaration_ } from '../models/Paginated_JPKDeclaration_';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class JpkReportingService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param requestBody
     * @returns JPKDeclaration Ok
     * @throws ApiError
     */
    public generateMerchantsItp(
        requestBody: GenerateITPBody,
    ): Observable<JPKDeclaration> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/reports/jpk/merchant/itp',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param merchantId
     * @param requestBody
     * @returns JPKDeclaration Ok
     * @throws ApiError
     */
    public generateMerchantItpz(
        merchantId: string,
        requestBody: Omit_GenerateMerchantITPZPayload_merchantId_or_adminUserId_,
    ): Observable<JPKDeclaration> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/reports/jpk/merchant/{merchantId}/itp-z',
            path: {
                'merchantId': merchantId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param status
     * @param last
     * @param limit
     * @returns Paginated_JPKDeclaration_ Ok
     * @throws ApiError
     */
    public listJpk(
        status?: JPKDeclarationStatus,
        last?: EncryptedLastItem,
        limit: number = 20,
    ): Observable<Paginated_JPKDeclaration_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/reports/jpk',
            query: {
                'status': status,
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param id
     * @param formData
     * @returns void
     * @throws ApiError
     */
    public signJpk(
        id: string,
        formData: {
            file: Blob;
        },
    ): Observable<void> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/reports/jpk/{id}/signed',
            path: {
                'id': id,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id
     * @returns any Ok
     * @throws ApiError
     */
    public getJpkUploadDocument(
        id: string,
    ): Observable<any> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/reports/jpk/{id}/upload-document',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns JPKDeclaration Ok
     * @throws ApiError
     */
    public fetchJpkStatus(
        id: string,
    ): Observable<JPKDeclaration> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/reports/jpk/{id}/status',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns string Ok
     * @throws ApiError
     */
    public fetchJpkOcr(
        id: string,
    ): Observable<string> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/reports/jpk/{id}/ocr',
            path: {
                'id': id,
            },
        });
    }

}