/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import type { Observable } from 'rxjs';

import type { EncryptedLastItem } from '../models/EncryptedLastItem';
import type { LocalDateString } from '../models/LocalDateString';
import type { Paginated_PspTrxSettlement_ } from '../models/Paginated_PspTrxSettlement_';
import type { Paginated_PspTrxSettlementLine_ } from '../models/Paginated_PspTrxSettlementLine_';
import type { PspId } from '../models/PspId';
import type { PspTrxSettlement } from '../models/PspTrxSettlement';

import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

@Injectable()
export class PspTransactionSettlementsService {

    constructor(private readonly http: HttpClient) {}

    /**
     * @param last
     * @param limit
     * @returns Paginated_PspTrxSettlement_ Ok
     * @throws ApiError
     */
    public list(
        last?: EncryptedLastItem,
        limit: number = 20,
    ): Observable<Paginated_PspTrxSettlement_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/psp-transaction-settlements',
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

    /**
     * @param formData
     * @returns PspTrxSettlement Ok
     * @throws ApiError
     */
    public create(
        formData: {
            pspId: string;
            file: Blob;
        },
    ): Observable<PspTrxSettlement> {
        return __request(OpenAPI, this.http, {
            method: 'POST',
            url: '/v1/psp-transaction-settlements',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param pspId
     * @param date
     * @returns PspTrxSettlement Ok
     * @throws ApiError
     */
    public retrieve(
        pspId: PspId,
        date: LocalDateString,
    ): Observable<PspTrxSettlement> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/psp-transaction-settlements/{pspId}/{date}',
            path: {
                'pspId': pspId,
                'date': date,
            },
        });
    }

    /**
     * @param pspId
     * @param date
     * @returns any Ok
     * @throws ApiError
     */
    public retrieveSignedUrl(
        pspId: PspId,
        date: LocalDateString,
    ): Observable<{
        url: any;
    }> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/psp-transaction-settlements/{pspId}/{date}/file',
            path: {
                'pspId': pspId,
                'date': date,
            },
        });
    }

    /**
     * @param pspId
     * @param date
     * @param last
     * @param limit
     * @returns Paginated_PspTrxSettlementLine_ Ok
     * @throws ApiError
     */
    public listLines(
        pspId: PspId,
        date: LocalDateString,
        last?: EncryptedLastItem,
        limit: number = 20,
    ): Observable<Paginated_PspTrxSettlementLine_> {
        return __request(OpenAPI, this.http, {
            method: 'GET',
            url: '/v1/psp-transaction-settlements/{pspId}/{date}/lines',
            path: {
                'pspId': pspId,
                'date': date,
            },
            query: {
                'last': last,
                'limit': limit,
            },
        });
    }

}