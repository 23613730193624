export enum ConfirmationDialogResult {
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
}

export enum AppuserStatus {
  BLOCK = 'BLOCK',
  ACTIVATE = 'ACTIVATE',
  DELETE = 'DELETE',
  DELETE_NOW = 'DELETE_NOW',
}

export enum ManualOnboardingSteps {
  VERIFY = 'VERIFY',
  INIT = 'INIT',
  COMPANY_DATA = 'COMPANY_DATA',
}

export enum CRUDMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  READ = 'READ',
}

export enum SupportedLanguageEnum {
  PL = 'pl',
  EN = 'en',
  UA = 'ua',
}

export enum MerchantStatusEnum {
  REGISTERED = 'REGISTERED',
  ACTIVE = 'ACTIVE',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  BLOCKED = 'BLOCKED',
}

export enum ConsentType {
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  APP_WIDE = 'APP_WIDE',
  MERCHANT = 'MERCHANT',
  ORDER = 'ORDER',
}

export enum ConsentUsageReason {
  CONTRACT_EXECUTION = 'CONTRACT_EXECUTION',
  MARKETING = 'MARKETING',
  NEWSLETTER = 'NEWSLETTER',
  ANALYTICS = 'ANALYTICS',
}

export enum MerchantCategoryContact {
  ACCOUNTING = 'ACCOUNTING',
  TECHNICAL = 'TECHNICAL',
  BUSINESS = 'BUSINESS',
  OTHER = 'OTHER',
}

export enum OAService {
  checkout = 'CHECKOUT',
  call = 'CALL',
}

export enum PeriodEnum {
  day = 'day',
  month = 'month',
}

export enum VatRate {
  EXEMPT = 'EXEMPT',
  VAT0000 = 'VAT0000',
  VAT0500 = 'VAT0500',
  VAT0800 = 'VAT0800',
  VAT2300 = 'VAT2300',
}

export enum MerchantUserPrivilegeEnum {
  MERCHANT_ADMIN = 'MERCHANT_ADMIN',
  MERCHANT_ACCOUNTING = 'MERCHANT_ACCOUNTING',
  MERCHANT_ANALYTICS = 'MERCHANT_ANALYTICS',
  MERCHANT_IT = 'MERCHANT_IT',
  MERCHANT_CUSTOMER_SERVICE = 'MERCHANT_CUSTOMER_SERVICE',
}

export enum SqsErrorStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  HANDLED = 'HANDLED',
  RETRY = 'retry',
  ARCHIVE = 'archive',
}

export enum MerchantRegistrationSteps {
  COMPANY = 0,
  CONTRACT = 1,
  PRICING = 2,
  CONSENTS = 3,
  CONTACT = 4,
  INTEGRATION_PROFILE = 5,
  USERS = 6,
  RETURNS = 7,
}

export enum ConsentDocumentType {
  INLINE = 'INLINE',
  URL = 'URL',
}

export enum ConsentDefinitionId {
  MOBILE_APP_APPROVAL_CHILD = 'MOBILE_APP_APPROVAL_CHILD',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  PRIVACY_POLICY = 'PRIVACY_POLICY',
  TOPUP_PRIVACY_POLICY = 'TOPUP_PRIVACY_POLICY',
  TOPUP_TERMS_AND_CONDITIONS = 'TOPUP_TERMS_AND_CONDITIONS',
  MOBILE_APP_APPROVAL_TEENAGER = 'MOBILE_APP_APPROVAL_TEENAGER',
  MERCHANT_TERMS_AND_CONDITION_ID = 'MERCHANT_TERMS_AND_CONDITION_ID',
  MERCHANT_PRIVACY_POLICY_ID = 'MERCHANT_PRIVACY_POLICY_ID',
  MERCHANT_CONTRACT_ID = 'MERCHANT_CONTRACT_ID',
}

export enum ReportsType {
  ORDERS = 'orders',
  TOPUPS = 'topups',
  CASHOUTS = 'cashouts',
  TRANSASCTIONS = 'transactions',
}

export enum PspIdTypeEnum {
  FISERV = 'FISERV',
  ITCARD = 'ITCARD',
}

export enum PspTransactionStatusEnum {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum KycAttemptStatusEnum {
  'INITIATED' = 'INITIATED',
  'REVIEW' = 'REVIEW',
  'VERIFIED_AUTO' = 'VERIFIED_AUTO',
  'VERIFIED_MANUAL' = 'VERIFIED_MANUAL',
  'REVOKED' = 'REVOKED',
  'BLOCKED' = 'BLOCKED',
  'FAILED' = 'FAILED',
  'ON_HOLD' = 'ON_HOLD',
  'SANCTION_HIT' = 'SANCTION_HIT',
}

export enum MerchantDeliveryOptionsEnum {
  INPOST_APM = 'INPOST_APM',
  ORLEN_APM = 'ORLEN_APM',
  POCZTA_POLSKA_APM = 'POCZTA_POLSKA_APM',
  DHL_PICKUP = 'DHL_PICKUP',
  DPD_PICKUP = 'DPD_PICKUP',
  INSTORE_PICKUP = 'INSTORE_PICKUP',
  DHL_COURIER = 'DHL_COURIER',
  DPD_COURIER = 'DPD_COURIER',
  FEDEX_COURIER = 'FEDEX_COURIER',
  GEIS_COURIER = 'GEIS_COURIER',
  GLS_COURIER = 'GLS_COURIER',
  INPOST_COURIER = 'INPOST_COURIER',
  POCZTEX_COURIER = 'POCZTEX_COURIER',
  UPS_COURIER = 'UPS_COURIER',
  ELECTRONIC = 'ELECTRONIC',
}

export enum OrderStatusEnum {
  NEW = 'NEW',
  ORDERED = 'ORDERED',
  DONE = 'DONE',
  CANCELLED = 'CANCELLED',
  CANCELLED_MERCHANT = 'CANCELLED_MERCHANT',
  CANCELLED_TECHNICAL = 'CANCELLED_TECHNICAL',
}

export enum OrderFailureTypes {
  BASKET = 'BASKET',
  PAYMENT = 'PAYMENT',
  MERCHANT = 'MERCHANT',
  UNKNOWN = 'UNKNOWN',
}

export enum CertaintyStatusEnum {
  UNCERTAIN = 'UNCERTAIN',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED',
}

export enum MerchantRepresantativeRoles {
  President = 'Prezes',
  Member = 'Członek Zarządu',
  Proxy = 'Prokurent',
  Plenipotentiary = 'Pełnomocnik',
  Other = 'Inne',
}

export enum JPKDeclarationStatusEnum {
  READY_FOR_SIGNING = 'READY_FOR_SIGNING',
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  ERROR = 'ERROR',
}

export enum PspTrxSettlementLineStatusEnum {
  NEW = 'NEW',
  MATCHED = 'MATCHED',
  ERROR = 'ERROR',
}
